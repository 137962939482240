import axios from 'axios'

const appEnv = process.env.REACT_APP_ENV
console.log('Here is the env: ', process.env.REACT_APP_ENV, appEnv)
const todoApiUrl = appEnv === 'production' ? 'https://power-todo-api.herokuapp.com/api/' : 'http://localhost:5000/api/'
// const serviceToken = localStorage.getItem('todoToken') ? localStorage.getItem('todoToken') : ''

const todoAPI = axios.create({
  baseURL: todoApiUrl,
  timeout: 1000,
  // headers: { authorization: `Bearer ${serviceToken}` }
})

const http = { axios, todoAPI }
export default http
