import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import ICategory from '../../models/interfaces/ICategory'
import { getAllCategoriesAsync } from '../../services/categoriesService'
import { addTodoAsync } from '../../services/todoService'
import toast, { Toaster } from 'react-hot-toast'
import { useAppSelector } from '../../hooks/hooks'

import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'

type AddTodoFormProps = {
  showForm: boolean
  handleToggleAddForm: () => void
  refreshPageData: () => void
}

const AddTodoForm = ({ showForm, handleToggleAddForm, refreshPageData }: AddTodoFormProps) => {
  const { userId } = useAppSelector((state) => state.users)
  const [categories, setCategories] = useState<Array<ICategory>>([])
  const [category, setCategory] = useState<string>('')
  const [todoTitle, setTodoTitle] = useState<string>('')
  const [todoDescription, setTodoDescription] = useState<string>('')

  useEffect(() => {
    getCategoryData()
  }, [])

  const getCategoryData = async () => {
    try {
      const data = await getAllCategoriesAsync()
      setCategories(data)
    } catch ({ message }) {
      console.log(message)
    }
  }

  const addTodo = async () => {
    type AddTodoRequest = {
      userId: string
      title: string
      description: string
      category: string
      dateComplete: Date | null
      subNotes: Array<any>
    }

    let todo: AddTodoRequest = {
      userId: userId,
      title: '',
      description: '',
      category: '',
      dateComplete: null,
      subNotes: [],
    }

    if (todoTitle && todoDescription && category) {
      todo.title = todoTitle
      todo.description = todoDescription
      todo.category = category
    } else {
      toast.error('Please make sure all fields are complete')
      return 0
    }

    try {
      const status = await addTodoAsync(todo)
      if (status === 201) toast.success('Added Todo successfully')
      resetFormIntputs()
      refreshPageData()
      setTimeout(() => {
        handleToggleAddForm()
      }, 2000)
    } catch ({ message }) {
      toast.error('Could not add Todo')
    }
  }

  const resetFormIntputs = () => {
    setTodoTitle('')
    setCategory('')
    setTodoDescription('')
  }

  const handleChangeCategory = (event: SelectChangeEvent) => {
    console.log(event.target.value)
    setCategory(event.target.value)
  }
  return (
    <AddTodoContainer show={showForm}>
      <Toaster />
      <Paper elevation={8} sx={{ width: '100%', padding: '5%' }}>
        <Typography variant='h4' sx={{ textDecoration: 'underline', marginBottom: '2%' }}>
          ADD TODO
        </Typography>
        <div className='groupWrapper'>
          <TextField
            label='Title'
            style={{ width: '48%' }}
            value={todoTitle}
            onChange={(e) => {
              setTodoTitle(e.target.value)
            }}
          />
          <FormControl sx={{ minWidth: '48%' }}>
            <InputLabel>Category</InputLabel>
            <Select
              style={{ width: '100%' }}
              value={category}
              label='Category'
              onChange={(e) => {
                handleChangeCategory(e)
              }}
            >
              <MenuItem disabled>
                <em>Category</em>
              </MenuItem>
              {categories.map((_category) => {
                return <MenuItem value={_category.name}>{_category.name}</MenuItem>
              })}
            </Select>
          </FormControl>
        </div>
        <div className='groupWrapper'>
          <TextField
            label='Description'
            multiline
            style={{ width: '100%' }}
            value={todoDescription}
            onChange={(e) => {
              setTodoDescription(e.target.value)
            }}
          />
        </div>
        <div className='groupWrapper'>
          <Button
            className='todo-btn'
            variant='appCustom'
            color='success'
            onClick={() => {
              addTodo()
            }}
          >
            Save
          </Button>
          <Button
            className='todo-btn'
            variant='appCustom'
            color='error'
            onClick={() => {
              handleToggleAddForm()
            }}
          >
            Cancel
          </Button>
        </div>
      </Paper>
    </AddTodoContainer>
  )
}

export default AddTodoForm

const AddTodoContainer = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  width: 100%;
  min-width: 350px;
  max-width: 1250px;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  padding: 2%;
  transition: all 0.3s ease;

  .groupWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2%;
  }

  .todo-btn {
    width: 30%;
    height: 45px;
  }
`
