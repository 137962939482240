import { createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../../config/http-service'
import { store } from '../../store'
import { loginUserStart, loginUserSuccess, loginUserFailure } from './usersSlice'

export const fetchUserAsync = createAsyncThunk('users/fetchUser', async (userCreds: { email: string; password: string }) => {
  store.dispatch(loginUserStart())
  http.todoAPI
    .post('account/login', userCreds)
    .then((res) => {
      localStorage.setItem('todoToken', res.data.token)
      store.dispatch(loginUserSuccess(res.data))
    })

    .catch((error: any) => {
      store.dispatch(loginUserFailure(error.response.data.message))
    })
})
