import React, { useEffect } from 'react'
import './App.scss'
import placeHolderImg from './public/images/placeholder-profile-img.png'
import routes from './routes/index'
import { Routes, Route, Link } from 'react-router-dom'
import LogRocket from 'logrocket'

import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import CategoryIcon from '@mui/icons-material/Category'
import PrivateRoute from './components/PrivateRoute'
import { useAppSelector } from './hooks/hooks'

const drawerWidth = 240

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

function App() {
  const theme = useTheme()
  const { userId, email, firstName, lastName, isLogggedIn } = useAppSelector((state) => state.users)
  const [proImage, setProImage] = React.useState<string>('')
  const [open, setOpen] = React.useState(false)
  const handleDrawerOpen = () => {
    setOpen(true)
  }

  useEffect(() => {
    setProImage('')
    LogRocket.init('cozuxe/power-todo-app')
  }, [])

  useEffect(() => {
    isLogggedIn &&
      LogRocket.identify('POWER_TODO_APP', {
        name: `${firstName} ${lastName}`,
        email: email,
        id: userId,
      })
    // eslint-disable-next-line
  }, [isLogggedIn])

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position='fixed' open={open}>
        <Toolbar>
          <IconButton color='inherit' aria-label='open drawer' onClick={handleDrawerOpen} edge='start' sx={{ mr: 2, ...(open && { display: 'none' }) }}>
            <MenuIcon className='white-font' />
          </IconButton>
          <Typography variant='h6' noWrap component='div'>
            Power Todos
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'space-around', width: '18%', marginLeft: '2%' }}>
            <Link style={{ fontSize: '18px', color: '#fff', textDecoration: 'none' }} to='/login'>
              Login
            </Link>
            <Link style={{ fontSize: '18px', color: '#fff', textDecoration: 'none' }} to='/create-account'>
              Create Account
            </Link>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            color: '#fff',
            backgroundColor: '#1976d2',
          },
        }}
        variant='persistent'
        anchor='left'
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>{theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}</IconButton>
        </DrawerHeader>
        <div className='app-profile-wrapper'>
          <ProfileImage profileImage={proImage} />
          <h4 className='app-profile-user-name white-font'>{firstName && firstName + ' ' + lastName}</h4>
        </div>
        <Divider />
        <List>
          {[
            { iconName: 'Todos', path: '/', icon: <FormatListBulletedIcon className='white-font' /> },
            { iconName: 'Categories', path: '/categories', icon: <CategoryIcon className='white-font' /> },
          ].map((data, index) => (
            <Link key={data.iconName} to={data.path} style={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>{data.icon}</ListItemIcon>
                  <ListItemText primary={data.iconName} />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
        <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Routes>
          {routes.map((route, index) => {
            return route.protected ? (
              <Route
                key={index}
                path={route.pathName}
                element={
                  <PrivateRoute>
                    <route.component />{' '}
                  </PrivateRoute>
                }
              />
            ) : (
              <Route key={index} path={route.pathName} element={<route.component />} />
            )
          })}
        </Routes>
      </Main>
    </Box>
  )
}

export default App

const ProfileImage = styled('div')<{ profileImage: string }>`
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-image: url(${(props) => (props.profileImage ? props.profileImage : placeHolderImg)});
  background-repeat: none;
  background-position: center;
  background-size: cover;
`
