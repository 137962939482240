import { createTheme } from '@mui/material/styles'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    appCustom: true
  }
}

const customAppTheme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'appCustom', color: 'success' },
          style: {
            backgroundColor: '#8dc63f',
            ':hover': { backgroundColor: '#7db135', color: '#fff', boxShadow: '0 5px 8px 0 rgba(0,0,0,0.3)' },
          },
        },
        {
          props: { variant: 'appCustom', color: 'error' },
          style: {
            backgroundColor: '#e5445c',
            ':hover': { backgroundColor: '#e3354f', color: '#fff', boxShadow: '0 5px 8px 0 rgba(0,0,0,0.3)' },
          },
        },
      ],
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
  },
})

const girlyTheme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'appCustom', color: 'success' },
          style: {
            backgroundColor: '#e3358c',
            ':hover': { backgroundColor: '#7db135', color: '#fff', boxShadow: '0 5px 8px 0 rgba(0,0,0,0.3)' },
          },
        },
        {
          props: { variant: 'appCustom', color: 'error' },
          style: {
            backgroundColor: '#f49c12 ',
            ':hover': { backgroundColor: '#e3354f', color: '#fff', boxShadow: '0 5px 8px 0 rgba(0,0,0,0.3)' },
          },
        },
      ],
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
  },
})
const themes = {customAppTheme, girlyTheme}
export default  themes
