import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface IUsersState {
  isLogggedIn: boolean
  firstName: string
  lastName: string
  email: string
  userId: string
  token: string
  fetching: boolean
  error: any
}

const initialState: IUsersState = {
  isLogggedIn: false,
  firstName: '',
  lastName: '',
  email: '',
  userId: '',
  token: '',
  fetching: false,
  error: '',
}

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    loginUserStart: (state) => {
      state.error = ''
      state.fetching = true
    },
    loginUserSuccess: (state, action: PayloadAction<any>) => {
      state.fetching = false
      state.firstName = action.payload.firstName
      state.lastName = action.payload.lastName
      state.email = action.payload.email
      state.userId = action.payload._id
      state.token = action.payload.token
      state.isLogggedIn = true
      state.fetching = false
    },
    loginUserFailure: (state, action: PayloadAction<any>) => {
      state.error = action.payload
      state.fetching = false
    },
  },
})

export const { loginUserStart, loginUserSuccess, loginUserFailure } = userSlice.actions

export default userSlice.reducer
