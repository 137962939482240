import React, { useState, useEffect } from 'react'
import '../styles/Home.scss'
import moment from 'moment'
import ITodo from '../models/interfaces/ITodo'
import { useAppSelector } from '../hooks/hooks'
import http from '../config/http-service'

import { getAllTodosAsync } from '../services/todoService'

import { Link } from 'react-router-dom'
import Layout from '../components/Layout'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CountCard from '../components/card-count'
import AddIcon from '@mui/icons-material/Add'
import AddTodoForm from '../components/add-todo-form'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import IconButton from '@mui/material/IconButton'
import { Collapse } from '@mui/material'

const Home = () => {
  const { userId, isLogggedIn } = useAppSelector((state) => state.users)
  const [localTodoData, setLocalTodoData] = useState<Array<ITodo> | any>([])
  const [todosComplete, setTodosComplete] = useState<number>(0)
  const [todoNotComplete, setTodosNotComplete] = useState<number>(0)
  const [showAddForm, setShowAddForm] = useState<boolean>(false)
  useEffect(() => {
    if (isLogggedIn) {
      const authToken = localStorage.getItem('todoToken') || ''
      http.todoAPI.defaults.headers.common['Authorization'] = `Bearer ${authToken}`
      getTodoData()
    }
    // eslint-disable-next-line
  }, [isLogggedIn])

  useEffect(() => {
    if (localTodoData) {
      setTodosComplete(calculateComplete())
      setTodosNotComplete(calculateNotComplete())
    }
    // eslint-disable-next-line
  }, [localTodoData])

  const getTodoData = async () => {
    try {
      const data = await getAllTodosAsync(userId)
      console.log(data)
      setLocalTodoData(data)
    } catch ({ message }) {
      console.log(message)
    }
  }

  // const completeTodo = (id: number) => {
  //   const indexOfItem = localTodoData.findIndex((ele: any) => ele.id === id)
  //   if (indexOfItem !== -1) {
  //     localTodoData[indexOfItem].completed = !localTodoData[indexOfItem].completed
  //     setLocalTodoData([...localTodoData])
  //   }
  // }

  const calculateComplete = (): number => {
    const records = localTodoData.filter((_todo: ITodo) => _todo.completed)
    return records.length
  }
  const calculateNotComplete = (): number => {
    const records = localTodoData.filter((_todo: ITodo) => !_todo.completed)
    return records.length
  }

  const handleToggleAddForm = () => {
    setShowAddForm(!showAddForm)
  }
  return (
    <Layout>
      <div style={{ display: 'flex', flexDirection: 'column', width: '80%', alignItems: 'center', maxWidth: '1250px' }}>
        <Typography variant='h4'>HEADS UP TODO</Typography>
        <Box className='home-tile-wrapper' component='div'>
          <CountCard title={'Complete'} quantity={todosComplete} bgColor={'#8dc63f'} />
          <CountCard title={'Not Complete'} quantity={todoNotComplete} bgColor={'#e5445c'} />
        </Box>

        <Box component='div' style={{ marginTop: '5%', display: 'flex', width: '100%', alignItems: 'center', flexDirection: 'column' }}>
          <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'flex-end' }}>
            {!showAddForm && (
              <Button
                variant='appCustom'
                color='success'
                style={{ width: '175px' }}
                onClick={() => {
                  handleToggleAddForm()
                }}
              >
                <AddIcon /> ADD TODO
              </Button>
            )}
          </div>
          <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
            <AddTodoForm showForm={showAddForm} handleToggleAddForm={handleToggleAddForm} refreshPageData={getTodoData} />
          </div>
        </Box>

        <Box component='div' style={{ marginTop: '5%', width: '100%' }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, maxWidth: '1250px' }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Title</TableCell>
                  <TableCell align='right'>Category</TableCell>
                  <TableCell align='right'>Date Created</TableCell>
                  <TableCell align='right'>Date Completed</TableCell>
                  <TableCell align='right'>Completed</TableCell>
                  <TableCell align='right'>View Notes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{localTodoData && localTodoData.length > 0 && localTodoData.map((row: any) => <InnerRowData row={row} />)}</TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
    </Layout>
  )
}

const InnerRowData = ({ row }: { row: ITodo }) => {
  const [open, setOpen] = useState<boolean>(false)

  const completeTodo = (_id: string) => {}

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          {row.title}
        </TableCell>
        <TableCell align='right'>{row.category}</TableCell>
        <TableCell align='right'>{moment(row.createdAt).format('LL LT')}</TableCell>
        <TableCell align='right'>{row.dateComplete}</TableCell>
        <TableCell
          align='right'
          onClick={() => {
            completeTodo(row._id)
          }}
        >
          {row.completed ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
        </TableCell>
        <TableCell align='right'>
          <Link key={row._id} to={`/note-details/${row._id}`} style={{ textDecoration: 'none' }}>
            View{' '}
          </Link>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box>
              <Typography variant='h6' gutterBottom component='div'>
                Quick View
              </Typography>
              <p style={{ minWidth: '350px', width: '40vw' }}>
                <strong>Description:</strong>&nbsp;{row.description}
              </p>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default Home
