import React, { useState, useEffect } from 'react'
import ptLogo from '../public/images/PT_Logo.png'
import Layout from '../components/Layout'
import { Link, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../hooks/hooks'
import { fetchUserAsync } from '../store/features/users/userThunks'
import toast, { Toaster } from 'react-hot-toast'

import { Grid, Box, TextField, Button, Typography } from '@mui/material'

const Login = () => {
  const { isLogggedIn, error } = useAppSelector((state) => state.users)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  useEffect(() => {
    isLogggedIn && toast.success('Login Successful')
    setTimeout(() => {
      isLogggedIn && navigate('/', { replace: true }) // ensures that when in a logged in status the user cannot try to re-login as we expect also when a user signs in successfully the user gets redirected to home page
    }, 1500)
    // eslint-disable-next-line
  }, [isLogggedIn])

  useEffect(() => {
    error && toast.error(error)
  }, [error])

  const login = async (e: any) => {
    e.preventDefault()
    dispatch(fetchUserAsync({ email, password }))
  }

  const handleFieldsFilled = (): boolean => {
    return !email || !password ? true : false
  }

  return (
    <Layout>
      <Toaster />
      <Grid container spacing={2}>
        <Grid item xs={12} md={5} style={{ minHeight: 'calc(100vh - 80px)', background: 'linear-gradient(159deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: '0' }}>
          <Box component='div' sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ width: '400px', backgroundColor: 'rgba(255,255,255,0.2)', borderRadius: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: '0 5px 12px 0 rgba(0,0,0,0.3)' }}>
              <img src={ptLogo} width='350' alt='power todo logo' />
            </div>
            <Typography variant='h2' style={{ color: '#fff', fontWeight: 700, marginTop: '3%' }}>
              Power Todo
            </Typography>
            <Typography variant='h4' style={{ color: '#fff', textAlign: 'center' }}>
              Welcome Back - Let's POWER your day
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={7} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', padding: '0' }}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '50vh', minWidth: '395px', width: '70%', maxWidth: '600px', textAlign: 'center', boxShadow: '0 5px 8px 0 rgba(0,0,0,0.2)', borderRadius: '5px', backgroundColor: 'rgba(0,0,0,0.05)' }}>
            <Typography variant='h2' style={{ color: 'rgba(0,0,0,0.5)', fontWeight: 700, marginTop: '20%' }}>
              LOGIN
            </Typography>
            <Box
              component='form'
              onSubmit={(e: any) => {
                login(e)
              }}
              sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '60%',
                height: '50vh',
                padding: ' 0 5% 10% 5%',
              }}
              noValidate
              autoComplete='off'
            >
              <TextField
                style={{ width: '100%' }}
                type='text'
                value={email}
                label='Email'
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              />
              <TextField
                style={{ width: '100%' }}
                type='password'
                value={password}
                label='Password'
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
              />
              <Button variant='appCustom' color='success' type='submit' disabled={handleFieldsFilled()} style={{ width: '100%', height: '60px', fontSize: '18px' }}>
                Login
              </Button>
              <div style={{ marginTop: '5%' }}>
                <Link to='/create-account' style={{ textDecoration: 'none' }}>
                  <Typography variant='h5' style={{ color: 'rgba(0,0,0,0.5)' }}>
                    Don't have an account yet?
                  </Typography>
                </Link>
              </div>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Login
