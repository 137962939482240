import http from '../config/http-service'

export const addSubtaskAsync = async (subtask: any, todoId: string) => {
  try {
    return await http.todoAPI.post(`subtask/add-task/${todoId}`, subtask)
  } catch ({ message }) {
    console.log(message)
  }
}

export const updateSubtaskAsync = async (todoId: string, subtaskId: string, subtask: { title: string; completed: boolean }) => {
  try {
    return await http.todoAPI.put(`subtask/update-subtask/${todoId}/${subtaskId}`, subtask)
  } catch ({ message }) {
    console.log(message)
  }
}
