import http from '../config/http-service'

export const getAllCategoriesAsync = async () => {
  try {
    const response = await http.todoAPI.get('category')
    return response.data
  } catch ({ message }) {
    console.log(message)
  }
}
