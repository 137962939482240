import React from 'react'
import { useAppSelector } from '../hooks/hooks'
import { Navigate } from 'react-router-dom'
import { isExpired } from 'react-jwt'
// https://www.npmjs.com/package/react-jwt

// https://dev.to/iamandrewluca/private-route-in-react-router-v6-lg5

const PrivateRoute = ({ children }: { children: any }) => {
  const { isLogggedIn, token } = useAppSelector((state) => state.users)
  // validate token as well

  return isLogggedIn && !isExpired(token) ? children : <Navigate to='/login' />
}

export default PrivateRoute
