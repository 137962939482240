import http from '../config/http-service'

export const createAccountAsync = async (user: any) => {
  try {
    const response = await http.todoAPI.post('account/create-account', user)
    return response.status
  } catch ({ message }) {
    console.log(message)
  }
}

export const loginAsync = async (userCreds: any) => {
  try {
    const response = await http.todoAPI.post('account/login', userCreds)
    if (response.status === 200) {
      localStorage.setItem('todoToken', response.data.token)
      localStorage.setItem('todoUser', response.data._id)
    }
    return response.data
  } catch ({ message }) {
    console.log(message)
  }
}
