import Home from '../pages/Home'
import Categories from '../pages/Categories'
import NoteDetails from '../pages/NoteDetails'
import Login from '../pages/Login'
import CreateAccount from '../pages/CreateAccount'

interface Route {
  pathName: string
  component: any
  protected: boolean
}

const routes: Array<Route> = [
  {
    pathName: '/',
    component: Home,
    protected: true,
  },
  {
    pathName: '/categories',
    component: Categories,
    protected: true,
  },
  {
    pathName: '/note-details/:id',
    component: NoteDetails,
    protected: true,
  },
  {
    pathName: '/login',
    component: Login,
    protected: false,
  },
  {
    pathName: '/create-account',
    component: CreateAccount,
    protected: false,
  },
]

export default routes
