import http from '../config/http-service'

type TodoRequest = {
  title: string
  description: string
  category: string
  dateComplete: Date | null
  subNotes: Array<any>
}

export const getAllTodosAsync = async (userId: string) => {
  try {
    const response = await http.todoAPI.get(`todos/${userId}`)
    return response.data
  } catch ({ message }) {
    console.log(message)
  }
}

export const getTodoByIdAsync = async (id: string) => {
  try {
    const response = await http.todoAPI.get(`todos/todo/${id}`)
    return response.data
  } catch ({ message }) {
    return message
  }
}

export const addTodoAsync = async (todoData: TodoRequest) => {
  try {
    const response = await http.todoAPI.post(`todos/add-todo`, todoData)
    return response.status
  } catch ({ message }) {
    return message
  }
}

export const updateTodoAsync = async (todoId: string, todoData: any): Promise<any> => {
  try {
    return await http.todoAPI.put(`todos/update-todo/${todoId}`, todoData)
  } catch ({ message }) {
    return message
  }
}

export const removeTodoByIdAsync = async (id: string) => {
  try {
    const response = await http.todoAPI.delete(`todos/remove-todo/${id}`)
    return response.status
  } catch ({ message }) {
    return message
  }
}
