import React, { useState, useEffect } from 'react'
import ISubTask from '../../models/interfaces/ISubTask'
import styled from 'styled-components'
import moment from 'moment'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { TextField } from '@mui/material'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { updateSubtaskAsync } from '../../services/subtaskService'

type SubtaskProps = {
  todoId: string
  subtask: ISubTask
}

const Subtask = ({ todoId, subtask }: SubtaskProps) => {
  const [title, setTitle] = useState<string>('')
  const [status, setStatus] = useState<boolean>(false)
  const [editMode, setEditMode] = useState<boolean>(false)

  useEffect(() => {
    setTitle(subtask.title)
    const incStatus = typeof subtask.completed === 'undefined' ? false : subtask.completed
    setStatus(incStatus)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCompleteSubtask = () => {
    setStatus(!status)
    handleUpdateSubtask(!status)
  }

  const handleUpdateSubtask = async (_status: boolean) => {
    try {
      await updateSubtaskAsync(todoId, subtask._id, { title, completed: _status })
    } catch ({ message }) {
      console.error(message)
    }
  }

  return (
    <SubtaskWrapper>
      <div className='description-content'>
        {editMode ? (
          <div className='edit-comp-wrapper'>
            <TextField
              label='Title'
              multiline
              style={{ width: '82%' }}
              value={title}
              onChange={(e) => {
                setTitle(e.target.value)
              }}
            />
            <CheckIcon
              style={{ width: '7.5%' }}
              onClick={() => {
                handleUpdateSubtask(status)
              }}
            />
            <CloseIcon
              style={{ width: '7.5%' }}
              onClick={() => {
                setEditMode(false)
              }}
            />
          </div>
        ) : (
          <p>{title}</p>
        )}
      </div>
      <div className='center-content'>
        <p>created:&nbsp;{moment(subtask.createdAt).format('l')}</p>
      </div>
      <div className='action-wrapper'>
        {status ? (
          <CheckCircleIcon
            style={{ color: '#8dc63f' }}
            onClick={() => {
              handleCompleteSubtask()
            }}
          />
        ) : (
          <RadioButtonUncheckedIcon
            style={{ color: '#e5445c' }}
            onClick={() => {
              handleCompleteSubtask()
            }}
          />
        )}
        <EditIcon
          onClick={() => {
            setEditMode(!editMode)
          }}
        />
        <DeleteIcon />
      </div>
    </SubtaskWrapper>
  )
}

export default Subtask

const SubtaskWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 1% 0;

  .description-content {
    width: 60%;
    word-wrap: word-break;

    .edit-comp-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    p {
      padding: 0;
    }
  }

  .center-content {
    width: 20%;
    p {
      padding: 0;
    }
  }

  .action-wrapper {
    display: flex;
    justify-content: space-around;
    width: 20%;
  }
`
