import React from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typograph from '@mui/material/Typography'

type CarCountProps = {
  title: string
  quantity: string | number
  bgColor?: string
}

const CountCard = ({ title, quantity, bgColor }: CarCountProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        padding: '2%',
        justifyContent: 'center',
        alignItems: 'center',
        '& > :not(style)': {
          m: 1,
          width: 150,
          height: 150,
        },
      }}
    >
      <Paper elevation={8} sx={{ backgroundColor: bgColor ? bgColor : '#fff', display: 'flex', flexDirection: 'column', padding: '2%', justifyContent: 'center', alignItems: 'center', textAlign: 'center', color: '#fff' }}>
        <Typograph variant='h5' sx={{ minHeight: '40%' }}>
          {title}
        </Typograph>
        <Typograph variant='h3' sx={{ marginTop: '5%' }}>
          {quantity}
        </Typograph>
      </Paper>
    </Box>
  )
}

export default CountCard
