import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { getTodoByIdAsync, removeTodoByIdAsync, updateTodoAsync } from '../services/todoService'
import { getAllCategoriesAsync } from '../services/categoriesService'
import ITodo from '../models/interfaces/ITodo'
import toast, { Toaster } from 'react-hot-toast'

import Layout from '../components/Layout'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import ISubTask from '../models/interfaces/ISubTask'
import Button from '@mui/material/Button'
import ICategory from '../models/interfaces/ICategory'
import { addSubtaskAsync } from '../services/subtaskService'
import moment from 'moment'
import Subtask from '../components/subtask'

interface ITodoRequest {
  title: string
  description: string
  category: string
  completed: boolean
  createdAt: Date
  dateComplete: string
  subNotes: Array<ISubTask>
}

const NoteDetails = () => {
  const [recordData, setRecordData] = useState<ITodo | null>(null)
  const [categories, setCategories] = useState<Array<ICategory>>([])
  const [todoTitle, setTodoTitle] = useState<string>('')
  const [todoCatergory, setTodoCatergory] = useState<string>('')
  const [todoDescription, setTodoDescription] = useState<string>('')
  const [todoStatus, setTodoStatus] = useState<boolean>(false)

  const [newSubTask, setNewSubTask] = useState<string>('')
  const params = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    if (params.id) {
      getCategoryData()
      getTodoByData(params.id)
    }
  }, [params])

  useEffect(() => {
    recordData && mapAyncDataToLocal(recordData)
  }, [recordData])

  // BEGIN PAGE FETCH DATA / PAGE SETUP

  const getCategoryData = async () => {
    try {
      const data = await getAllCategoriesAsync()
      setCategories(data)
    } catch ({ message }) {
      console.log(message)
    }
  }
  const getTodoByData = async (id: string) => {
    try {
      const data = await getTodoByIdAsync(id)
      setRecordData(data)
    } catch ({ message }) {
      console.log(message)
    }
  }

  const mapAyncDataToLocal = (todo: ITodo) => {
    setTodoTitle(todo.title)
    setTodoCatergory(todo.category)
    setTodoDescription(todo.description)
    setTodoStatus(todo.completed)
  }

  const calcDateDiff = () => {
    const given = moment(recordData?.createdAt, 'YYYY-MM-DD')
    const current = moment().startOf('day')
    //Difference in number of days
    console.log(given, current)
    return moment.duration(current.diff(given)).asDays().toFixed(0)
  }

  // BEGIN TODO CRUD

  const handleUpdateTodo = async (noteId: string) => {
    const updatedTodo: ITodoRequest = {
      title: todoTitle,
      description: todoDescription,
      category: todoCatergory,
      completed: todoStatus,
      createdAt: recordData!.createdAt,
      dateComplete: recordData?.dateComplete ?? '',
      subNotes: [...(recordData?.subNotes ?? [])],
    }
    try {
      const response = await updateTodoAsync(noteId, updatedTodo)
      if (response?.status === 201) {
        toast.success('Todo updated successfully')
      }
    } catch ({ message }) {
      toast.error('Todo was not updated')
      console.error(message)
    }
  }

  const deleteTodo = async (id: string) => {
    try {
      const response = await removeTodoByIdAsync(id)
      if (response === 201) {
        toast.success('Todo removed successfully')
        setTimeout(() => {
          navigate('/', { replace: true })
        }, 2000)
      } else {
        toast.error('Todo was not removed')
      }
    } catch ({ message }) {
      console.error(message)
    }
  }

  // SUBTASK CRUD BEGIN

  const handleAddSubtask = async () => {
    try {
      const res = await addSubtaskAsync({ title: newSubTask }, recordData!._id)
      if (res?.status === 201) {
        toast.success('Subtask added successfully')
        setRecordData(res?.data) // reset state with new data
        setNewSubTask('')
      }
    } catch ({ message }) {
      toast.error('Subtask not added')
      console.log(message)
    }
  }

  // const handleUpdateSubtask = (noteId: string, taskId: string) => {}

  // const handleCompleteSubtask = () => {}

  // const handleDeleteSubtask = () => {}

  return (
    <Layout>
      <Toaster />
      <div style={{ display: 'flex', alignItems: 'flex-start', width: '80%' }}>
        <Typography variant='h4'>TODO DETAIL</Typography>
      </div>
      <div style={{ display: 'flex', width: '80%', marginTop: '2%' }}>
        <Link to='/' style={{ textDecoration: 'none' }}>
          <div style={{ display: 'flex', flexDirection: 'row', width: '80%', marginTop: '2%' }}>
            <DoubleArrowIcon style={{ transform: 'rotate(180deg)', height: '30px' }} />
            <span>
              <Typography variant='h5'>BACK</Typography>
            </span>
          </div>
        </Link>
      </div>
      <div style={{ width: '80%', marginTop: '5%' }}>
        <Paper
          elevation={3}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '2%' }}
          component='form'
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete='off'
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '80%' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start', width: '100%', margin: '2% 0' }}>
              <Typography variant='h4'>TODO INFO</Typography>
            </div>
            <Box sx={{ flexGrow: 1 }} style={{ width: '100%' }}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label='Title'
                    style={{ width: '100%' }}
                    value={todoTitle}
                    onChange={(e) => {
                      setTodoTitle(e.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl sx={{ m: 1, minWidth: '100%' }}>
                    <InputLabel>Category</InputLabel>
                    <Select
                      style={{ width: '100%' }}
                      value={todoCatergory}
                      label='Category'
                      onChange={(e) => {
                        setTodoCatergory(e.target.value)
                      }}
                    >
                      <MenuItem disabled>
                        <em>Category</em>
                      </MenuItem>
                      {categories.map((_category) => {
                        return <MenuItem value={_category.name}>{_category.name}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label='Description'
                    style={{ width: '100%' }}
                    multiline
                    rows={4}
                    defaultValue='Enter Text....'
                    value={todoDescription}
                    onChange={(e) => {
                      setTodoDescription(e.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <div style={{ padding: '2%' }}>
                    <Typography>
                      <>
                        <strong>Create On:</strong>&nbsp;{moment(recordData?.createdAt).format('l')}
                      </>
                    </Typography>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <Typography>
                      <strong>Completed On:</strong>&nbsp;{recordData?.dateComplete}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6} md={6}>
                  <div style={{ padding: '2%' }}>
                    <Typography>
                      <strong>Last Updated On:</strong>&nbsp;{moment(recordData?.updatedAt).format('l')}
                    </Typography>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <Typography>
                      <strong>Age of Todo:</strong>&nbsp;{calcDateDiff()}&nbsp;days
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} style={{ width: '100%' }}>
                  <div style={{ display: 'flex', justifyContent: 'baseline', width: '100%', padding: '2%' }}>
                    <Typography>
                      <strong>Completed :</strong>
                    </Typography>
                    &nbsp;
                    {todoStatus ? (
                      <CheckBoxIcon
                        style={{ color: '#8dc63f' }}
                        onClick={() => {
                          setTodoStatus(!todoStatus)
                        }}
                      />
                    ) : (
                      <CheckBoxOutlineBlankIcon
                        onClick={() => {
                          setTodoStatus(!todoStatus)
                        }}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </Box>

            <Box style={{ display: 'flex', justifyContent: 'space-around', width: '50%' }}>
              <Button
                variant='appCustom'
                color='success'
                onClick={() => {
                  handleUpdateTodo(recordData!._id)
                }}
              >
                Update
              </Button>
              <Button
                variant='appCustom'
                color='error'
                onClick={() => {
                  deleteTodo(recordData?._id as string)
                }}
              >
                Delete
              </Button>
            </Box>
          </div>
        </Paper>
        <Paper elevation={3} style={{ marginTop: '20px', padding: '2%' }}>
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '100%', padding: '1% 12%' }}>
            <Typography variant='h4' style={{ marginBottom: '2%' }}>
              SUBTASKS
            </Typography>
            <div>
              <TextField
                style={{ width: '50%' }}
                label='Add Subtask'
                multiline
                maxRows={4}
                value={newSubTask}
                onChange={(e) => {
                  setNewSubTask(e.target.value)
                }}
              />
              <Button
                style={{ height: '56px', left: '5px' }}
                variant='appCustom'
                color='success'
                onClick={() => {
                  handleAddSubtask()
                }}
              >
                Add
              </Button>
            </div>
            <ul style={{ listStyle: 'none', padding: 0 }}>
              {recordData?.subNotes &&
                recordData.subNotes.length > 0 &&
                recordData.subNotes.map((note: any, index: number) => {
                  return (
                    <li>
                      <Subtask todoId={recordData?._id} subtask={note} />
                    </li>
                  )
                })}
            </ul>
          </div>
        </Paper>
      </div>
    </Layout>
  )
}

export default NoteDetails
