import React, { useState } from 'react'
import Layout from '../components/Layout'
import { useNavigate } from 'react-router-dom'
import { createAccountAsync } from '../services/account'

const CreateAccount = () => {
  const navigate = useNavigate()
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const createNewAccount = async () => {
    try {
      if (firstName && lastName && email && password) {
        const status = await createAccountAsync({ firstName, lastName, email, password })
        if (status === 201) {
          // add toast and pause redirect to login
          navigate('/login')
        }
      } else {
        console.warn('Missing some required fields')
      }
    } catch ({ message }) {
      console.error(message)
    }
  }

  return (
    <Layout>
      <div>
        <h2>Create Account</h2>
        <input
          type='text'
          value={firstName}
          placeholder='First Name'
          onChange={(e) => {
            setFirstName(e.target.value)
          }}
        />
        <input
          type='text'
          value={lastName}
          placeholder='Last Name'
          onChange={(e) => {
            setLastName(e.target.value)
          }}
        />
        <input
          type='text'
          value={email}
          placeholder='Email Name'
          onChange={(e) => {
            setEmail(e.target.value)
          }}
        />
        <input
          type='password'
          value={password}
          placeholder='Password Name'
          onChange={(e) => {
            setPassword(e.target.value)
          }}
        />
        <input
          type='button'
          value='Create Now'
          onClick={() => {
            createNewAccount()
          }}
        />
      </div>
    </Layout>
  )
}

export default CreateAccount
