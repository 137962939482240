import React from 'react'
import './Layout.scss'

type LayoutProps = {
  children: any
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <main className='layout'>{children}</main>
    </>
  )
}

export default Layout
